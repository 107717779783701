body,
html {
  line-height: 1;
  font-family: 'PingFang SC', 'STHeitiSC-Light', 'Helvetica-Light', arial, sans-serif, 'Droid Sans Fallback';
  -webkit-tap-highlight-color: transparent;
}
path {
  fill: inherit !important;
}
.main-color {
  color: var(--main-color);
}
.yd-cell-right input {
  line-height: 100% !important;
  color: #333 !important;
}
.border-top-1px,
.border-right-1px,
.border-bottom-1px,
.border-left-1px {
  position: relative;
}
.border-top-1px:before,
.border-right-1px:before,
.border-bottom-1px:before,
.border-left-1px:before,
.border-top-1px:after,
.border-right-1px:after,
.border-bottom-1px:after,
.border-left-1px:after {
  content: "";
  display: block;
  position: absolute;
  transform-origin: 0 0;
  z-index: 3;
}
.border-top-1px:before {
  border-top: 1px solid #e1e1e1;
  left: 0;
  top: 0;
  width: 100%;
  transform-origin: 0 top;
}
.border-right-1px:after {
  border-right: 1px solid #f5f5f5;
  top: 0;
  right: 0;
  height: 100%;
  transform-origin: right 0;
}
.border-bottom-1px:after {
  border-bottom: 1px solid #e1e1e1;
  left: 0;
  bottom: 0;
  width: 100%;
  transform-origin: 0 bottom;
}
.border-left-1px:before {
  border-left: 1px solid #f5f5f5;
  top: 0;
  left: 0;
  height: 100%;
  transform-origin: left 0;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2) {
  .border-top-1px:before {
    width: 200%;
    transform: scale(0.5) translateZ(0);
  }
  .border-right-1px:after {
    height: 200%;
    transform: scale(0.5) translateZ(0);
  }
  .border-bottom-1px:after {
    width: 200%;
    transform: scale(0.5) translateZ(0);
  }
  .border-left-1px:before {
    height: 200%;
    transform: scale(0.5) translateZ(0);
  }
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .border-top-1px:before {
    width: 300%;
    transform: scale(0.333) translateZ(0);
  }
  .border-right-1px:after {
    height: 300%;
    transform: scale(0.333) translateZ(0);
  }
  .border-bottom-1px:after {
    width: 300%;
    transform: scale(0.333) translateZ(0);
  }
  .border-left-1px:before {
    height: 300%;
    transform: scale(0.333) translateZ(0);
  }
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.df {
  display: flex;
}
.alc {
  align-items: center;
}
.als {
  align-items: flex-start;
}
.jc-fs {
  justify-content: flex-start;
}
.jcc {
  justify-content: center;
}
.jc-sb {
  justify-content: space-between;
}
.jc-sa {
  justify-content: space-around;
}
.jc-end {
  justify-content: flex-end;
}
.fww {
  flex-wrap: wrap;
}
.fdc {
  flex-direction: column;
}
.fdr {
  flex-direction: row;
}
.bg-transparent {
  background: transparent !important;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.slide-top-enter,
.slide-top-leave-to {
  transform: translate(0, 100%);
}
.slide-top-enter-active,
.slide-top-leave-active {
  transition: all 0.3s ease;
}
.txt-over {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.stage-dialog {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  padding-bottom: 0.98rem;
  box-sizing: border-box;
}
.stage-dialog .stage-main {
  width: 100%;
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
}
.stage-dialog .stage-content {
  width: 100%;
  background: #fff;
  padding: 0.3rem;
  box-sizing: border-box;
  border-radius: 10px 10px 0px 0px;
  z-index: 999;
}
.stage-dialog .stage-content .title {
  width: 100%;
  text-align: center;
  color: #58b8d8;
  font-size: 0.4rem;
  margin-bottom: 0.5rem;
}
.stage-dialog .stage-content .stage-list {
  font-size: 0.28rem;
}
.stage-dialog .stage-content .stage-list li {
  margin-top: 0.2rem;
  background-color: #efefef;
  padding: 0.2rem;
  border-radius: 0.1rem;
  border: 1px solid #eceaeb;
  display: flex;
  justify-content: space-between;
}
.stage-dialog .stage-content .stage-list li .plans {
  color: #fe5d1c;
}
.stage-dialog .stage-content .stage-list li .money {
  font-size: 0.35rem;
  font-weight: bolder;
}
.stage-dialog .stage-content .stage-list li .gray {
  color: #000100;
}
.stage-dialog .stage-content .close {
  border-radius: 0.5rem;
  background-color: #efefef;
  width: 3rem;
  height: 0.8rem;
  line-height: 0.8rem;
  text-align: center;
  margin: 2rem auto 0.5rem auto;
}
